<router-outlet></router-outlet>

<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <div id="social-buttons-icon-rounded-component" class="tab-pane tab-example-result fade show active" role="tabpanel"
        aria-labelledby="social-buttons-icon-rounded-component-tab">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+5549991940845">
            <button class="btn btn-icon btn-2 btn-success" style="border-radius: 50%;" type="button" placement="left"
                ngbTooltip="Fale conosco">
                <span class="btn-inner--icon">
                    <img style="width:35px; height:55px;" src="./assets/icons/whatsapp.svg" alt="">
                </span>
            </button>
        </a>
    </div>
</div>

<div *ngIf="cookies == null" id="card-contact-component"
    class=" fixed-bottom tab-pane tab-example-result fade show active" role="tabpanel"
    aria-labelledby="card-contact-component-tab">
    <div id="ofBar" class="card">
        <!-- Card body -->
        <div class="card-body px-5">
            <div class="row align-items-center">
                <div class="col-md-8 ">
                    <div class="" id="ofBar-content">
                        A CAMARGO AGRONEGÓCIOS faz uso de Cookies, para aprimorar a experiência de uso. Ao
                        acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a
                        utilização.
                        <a href="https://bid365.app/storage/termos-condicoes.pdf" target="_blank"
                            rel="noopener noreferrer" class="text-white" style="font-weight: bold;">Confira nossa
                            Política de
                            Privacidade.</a>
                    </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center my-2">
                    <a rel="noopener noreferrer" class="mr-3 text-white" (click)="open(classic,'' , '')"
                        style="cursor: pointer;">Configurações</a>
                    <a class="btn-cookies" target="_blank" id="btn-bar" (click)="acceptCookies()">Aceitar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-md-8" id="ofBar" *ngIf="cookies == null">
        <div id="ofBar-content">A CAMARGO AGRONEGÓCIOS faz uso de Cookies, para aprimorar a experiência de uso. Ao
            acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização. <a
                href="https://bid365.app/storage/termos-condicoes.pdf" target="_blank" rel="noopener noreferrer"
                class="text-white" style="font-weight: bold;">Confira nossa Política de
                Privacidade.</a></div>
        <div class="col-md-4" id="ofBar-right">
            <a rel="noopener noreferrer" class="mr-3 text-white" (click)="open(classic,'' , '')"
                style="cursor: pointer;">Configurações</a>
            <a class="btn-cookies" target="_blank" id="btn-bar" (click)="acceptCookies()">Aceitar</a>
        </div>
         //<a (click)="refusedCookies()" id="close-bar">×</a> 
 </div>
</div> -->

<ng-template #classic let-c="close" let-d="dismiss">
    <div class="modal-content">
        <div class="modal-header">
            Configurações
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click');">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="jumbotron p-3 mb-2">
                <div class=" row">
                    <div class="col-md-9 col-lg-9 col-sm-9 col-9">Funcional</div>
                    <div class="col-md-3 col-lg-3 col-sm-3 col-3" style="float: right;"><label class="custom-toggle">
                            <input type="checkbox" checked="">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Não"
                                data-label-on="Sim"></span>
                        </label></div>
                </div>

            </div>
            <div class="jumbotron p-3 mb-2">
                <div class=" row">
                    <div class="col-md-9 col-lg-9 col-sm-9 col-9">Desempenho</div>
                    <div class="col-md-3 col-lg-3 col-sm-3 col-3" style="float: right;"><label class="custom-toggle">
                            <input type="checkbox" checked="">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Não"
                                data-label-on="Sim"></span>
                        </label></div>
                </div>

            </div>
            <div class="jumbotron p-3 mb-2">
                <div class=" row">
                    <div class="col-md-9 col-lg-9 col-sm-9 col-9">Analytics</div>
                    <div class="col-md-3 col-lg-3 col-sm-3 col-3" style="float: right;"><label class="custom-toggle">
                            <input type="checkbox" checked="">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Não"
                                data-label-on="Sim"></span>
                        </label></div>
                </div>
            </div>
            <div class="jumbotron p-3 mb-2">
                <div class=" row">
                    <div class="col-md-9 col-lg-9 col-sm-9 col-9">Propaganda</div>
                    <div class="col-md-3 col-lg-3 col-sm-3 col-3" style="float: right;"><label class="custom-toggle">
                            <input type="checkbox" checked="">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Não"
                                data-label-on="Sim"></span>
                        </label></div>
                </div>

            </div>
            <div class="jumbotron p-3 mb-2">
                <div class=" row">
                    <div class="col-md-9 col-lg-9 col-sm-9 col-9">Outras</div>
                    <div class="col-md-3 col-lg-3 col-sm-3 col-3" style="float: right;"><label class="custom-toggle">
                            <input type="checkbox" checked="">
                            <span class="custom-toggle-slider rounded-circle" data-label-off="Não"
                                data-label-on="Sim"></span>
                        </label></div>
                </div>

            </div>

            <button class="btn btn-icon btn-primary btn-3 mt-2 float-right pl-5 pr-5" type="submit">
                <span class="btn-inner--text " (click)="d('Cross click');">Salvar</span>
            </button>

        </div>


    </div>
</ng-template>