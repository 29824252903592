export const environment = {
   production: true,
   apiUrl: "https://bid365.app/api/",
   apiStorageUrl: "https://bid365.app/storage/",
   app: '46069e33-a84f-476e-bb34-412233611186'
 
 
   //Teste
   /*  apiUrl: 'https://bis365.com.br/bid365/api/',
    apiStorageUrl: 'https://bis365.com.br/bid365/storage/',
    app: '5c27cb0b-be1e-4b20-a34d-37cbe39c878c', */
 };
 