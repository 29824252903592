import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizeGuard } from './guards/authorize.guard';


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'auction-details', loadChildren: () => import('./pages/auction-details/auction-details.module').then(m => m.AuctionDetailsModule) },
  { path: 'auction-details/:id', loadChildren: () => import('./pages/auction-details/auction-details.module').then(m => m.AuctionDetailsModule) },
  { path: 'lot-detail', loadChildren: () => import('./pages/lot-detail/lot-detail.module').then(m => m.LotDetailModule) },
  { path: 'auction-schedule', loadChildren: () => import('./pages/auction-schedule/auction-schedule.module').then(m => m.AuctionScheduleModule) },
  { path: 'trading-floor', loadChildren: () => import('./pages/trading-floor/trading-floor.module').then(m => m.TradingFloorModule) },
  { path: 'online-auction', loadChildren: () => import('./pages/online-auction/online-auction.module').then(m => m.OnlineAuctionModule) },
  { path: 'auction-onlive', loadChildren: () => import('./pages/auction-onlive/auction-onlive.module').then(m => m.AuctionOnliveModule) },
  { path: 'auction-result', loadChildren: () => import('./pages/auction-result/auction-result.module').then(m => m.AuctionResultModule) },
  { path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthorizeGuard] },
  { path: 'dynamic-checkbox', loadChildren: () => import('./pages/dynamic-checkbox/dynamic-checkbox.module').then(m => m.DynamicCheckboxModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
