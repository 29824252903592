import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { AuthorizeGuard } from './guards/authorize.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from "ngx-toastr";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from "ngx-chips";
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
// import { NgxDropzoneModule } from 'ngx-dropzone';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    // NgxDropzoneModule,

    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserAnimationsModule

  ],
  //
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AuthorizeGuard,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
